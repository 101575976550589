<template>
  <div class="privileges grid grid-view-layout">
    <page-header />
    <info-page
      title="Management"
      description="Under construction."
    />
  </div>
</template>

<script>
import PageHeader from '@/components/domain/page-header.vue';
import InfoPage from '@/components/shared/info-page.vue';

import { useActorStore } from '@/store/actor';

import { storeToRefs } from 'pinia';

export default {
  components: {
    PageHeader,
    InfoPage,
  },

  setup() {
    const {
      product,
    } = storeToRefs(useActorStore());

    return {
      product,
    };
  },
};
</script>

<style scoped>
@media (min-width: 37.5rem) {
  .privileges__title {
    margin: 3rem 0 1rem;
  }
}
</style>
